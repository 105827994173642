var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card",class:_vm.$style.container},[_vm._m(0),_c('a-form',{staticClass:"mb-4",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              rules: [
                { required: true, message: 'Please input your full name' },
              ],
            },
          ]),expression:"[\n            'name',\n            {\n              rules: [\n                { required: true, message: 'Please input your full name' },\n              ],\n            },\n          ]"}],attrs:{"size":"large","placeholder":_vm.$t('profil.nom')}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email',
            {
              rules: [
                {
                  required: true,
                  message: 'Please input your email address',
                },
              ],
            },
          ]),expression:"[\n            'email',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Please input your email address',\n                },\n              ],\n            },\n          ]"}],attrs:{"size":"large","placeholder":"Adresse email"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [
                { required: true, message: 'Please input your password' },
              ],
            },
          ]),expression:"[\n            'password',\n            {\n              rules: [\n                { required: true, message: 'Please input your password' },\n              ],\n            },\n          ]"}],attrs:{"size":"large","type":"password","placeholder":_vm.$t('profil.mdp')}})],1),_c('a-button',{staticClass:"text-center w-100",attrs:{"type":"primary","size":"large","loading":_vm.loading,"htmlType":"submit"}},[_c('strong',[_vm._v("S'inscrire")])])],1),_vm._m(1)],1),_c('div',{staticClass:"text-center pt-2 mb-auto"},[_c('span',{staticClass:"mr-2"},[_vm._v("Already have an account?")]),_c('router-link',{staticClass:"kit__utils__link font-size-16",attrs:{"to":"/auth/login"}},[_vm._v("Sign in")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-dark font-size-24 mb-4"},[_c('strong',[_vm._v("Créer ton compte")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"mr-1"},[_vm._v("By signing up, you agree to the")]),_c('a',{staticClass:"kit__utils__link",attrs:{"href":"javascript: void(0);"}},[_vm._v("Terms of Service")]),_vm._v(" and "),_c('a',{staticClass:"kit__utils__link",attrs:{"href":"javascript: void(0);"}},[_vm._v("Privacy Policy")])])
}]

export { render, staticRenderFns }